import React, { useState, useRef, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Matter from 'matter-js';
import heartImage from './heart.png';

function App() {
  const [fromName, setFromName] = useState('');
  const [toName, setToName] = useState('');
  const [traits, setTraits] = useState('');
  const [loveLetter, setLoveLetter] = useState('');
  const [showHearts, setShowHearts] = useState(true);
  const [heartsFilled, setHeartsFilled] = useState(false);
  const [heartsPopped, setHeartsPopped] = useState(false);
  const [readyToShowLoveLetter, setReadyToShowLoveLetter] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [copySuccess, setCopySuccess] = useState('');
  const [userId, setUserId] = useState(null);
  const [credits, setCredits] = useState(5); // Start with 5 credits for testing
  const [audioUrl, setAudioUrl] = useState(null);
  const [isGeneratingAudio, setIsGeneratingAudio] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio());

  const totalSteps = 4;

  const sceneRef = useRef(null);
  const engineRef = useRef(null);
  const containerRef = useRef(null);

  // Constant user ID for testing
  const TEST_USER_ID = '1';

  // Use the environment variable for the API URL
  const API_URL = process.env.REACT_APP_API_URL || 'https://ailovenotes.com'; // Fallback to localhost for development
  console.log('API_URL', API_URL);
  useEffect(() => {
    fetchUserCredits(TEST_USER_ID);
  }, []);

  const fetchUserCredits = async (userId) => {
    try {
      const response = await fetch(`${API_URL}/api/user-credits/${userId}`); // Use the API_URL variable
      const data = await response.json();
      setCredits(data.credits);
    } catch (error) {
      console.error('Error fetching user credits:', error);
      setCredits(5); // Fallback to 5 credits if there's an error
    }
  };

  const estimateHeartCount = () => {
    const containerWidth = containerRef.current.clientWidth;
    const containerHeight = containerRef.current.clientHeight;
    const heartSize = 50; // This should match the size you're using for createHeart
    const heartArea = heartSize * heartSize;
    const containerArea = containerWidth * containerHeight;
    
    // We'll aim to fill about 70% of the screen
    const fillPercentage = 0.9;
    
    return Math.floor((containerArea * fillPercentage) / heartArea);
  };

  const copyToClipboard = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(loveLetter)
        .then(() => {
          setCopySuccess('Copied!');
          setTimeout(() => setCopySuccess(''), 2000);
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
          setCopySuccess('Copy failed');
        });
    } else {
      // Fallback method
      const textArea = document.createElement("textarea");
      textArea.value = loveLetter;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          setCopySuccess('Copied!');
          setTimeout(() => setCopySuccess(''), 2000);
        } else {
          setCopySuccess('Copy failed');
        }
      } catch (err) {
        console.error('Failed to copy: ', err);
        setCopySuccess('Copy failed');
      }
      document.body.removeChild(textArea);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current && engineRef.current && engineRef.current.render) {
        const containerWidth = containerRef.current.clientWidth;
        const containerHeight = containerRef.current.clientHeight;

        engineRef.current.render.canvas.width = containerWidth;
        engineRef.current.render.canvas.height = containerHeight;
        
        // Update world bounds when resizing
        Matter.Body.setPosition(engineRef.current.world.bounds.max, {
          x: containerWidth,
          y: containerHeight
        });
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (showHearts) {
      const { Engine, Render, Runner, Bodies, Composite, Events } = Matter;

      // Only create a new engine if it doesn't exist
      if (!engineRef.current) {
        const engine = Engine.create();
        engineRef.current = engine;
        const world = engine.world;

        const containerWidth = containerRef.current.clientWidth;
        const containerHeight = containerRef.current.clientHeight;

        const render = Render.create({
          element: sceneRef.current,
          engine: engine,
          options: {
            width: containerWidth,
            height: containerHeight,
            wireframes: false,
            background: 'transparent'
          }
        });

        Render.run(render);
        Runner.run(Runner.create(), engine);

        // Adjust the ground position to be at the bottom of the container
        const ground = Bodies.rectangle(
          containerWidth / 2,
          containerHeight + 10,
          containerWidth,
          22,
          { 
            isStatic: true, 
            render: { visible: false },
            restitution: 0.8, // Add bounce to the ground
            friction: 0.1     // Reduce friction of the ground
          }
        );
        const leftWall = Bodies.rectangle(0, containerHeight / 2, 10, containerHeight, { isStatic: true, render: { visible: false } });
        const rightWall = Bodies.rectangle(containerWidth, containerHeight / 2, 10, containerHeight, { isStatic: true, render: { visible: false } });
        Composite.add(world, [ground, leftWall, rightWall]);

        // Add a resize event listener to update the world boundaries
        const handleResize = () => {
          const newWidth = containerRef.current.clientWidth;
          const newHeight = containerRef.current.clientHeight;

          // Update render canvas size
          render.canvas.width = newWidth;
          render.canvas.height = newHeight;

          // Update ground position
          Matter.Body.setPosition(ground, {
            x: newWidth / 2,
            y: newHeight
          });

          // Update wall positions
          Matter.Body.setPosition(leftWall, {
            x: 0,
            y: newHeight / 2
          });
          Matter.Body.setPosition(rightWall, {
            x: newWidth,
            y: newHeight / 2
          });

          // Update wall heights
          Matter.Body.setVertices(leftWall, Matter.Bodies.rectangle(0, newHeight / 2, 10, newHeight).vertices);
          Matter.Body.setVertices(rightWall, Matter.Bodies.rectangle(newWidth, newHeight / 2, 10, newHeight).vertices);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }

      const heartSize = 50;
      const creamColors = ['#FFFDD0', '#F5F5DC', '#FAF0E6', '#FDF5E6', '#FFF8DC'];

      const createHeart = (x, y) => {
        const heart = Bodies.rectangle(x, y, heartSize, heartSize, {
          render: {
            sprite: {
              texture: heartImage,
              xScale: 0.15,
              yScale: 0.15
            }
          },
          restitution: 0.6, // Add bounce
          friction: 0.1,    // Reduce friction
          density: 0.001    // Make hearts lighter
        });
        heart.render.fillStyle = creamColors[Math.floor(Math.random() * creamColors.length)];
        Composite.add(engineRef.current.world, heart);
      };

      const dropHearts = (count) => {
        const containerWidth = containerRef.current.clientWidth;
        const dropHeart = (index) => {
          if (index < count) {
            setTimeout(() => {
              const x = Math.random() * (containerWidth - heartSize) + heartSize / 2;
              const y = Math.random() * -200;
              createHeart(x, y);
              dropHeart(index + 1);
            }, 80); // 100 milliseconds = 0.1 seconds
          }
        };
        dropHeart(0);
      };

      // Drop hearts for all steps, including the first one
      if (true) {
        console.log('estimateHeartCount', estimateHeartCount());
        console.log('totalSteps', totalSteps);
        console.log('Dropping Hearts');
        console.log('estimateHeartCount/totalSteps', estimateHeartCount()/totalSteps);
        dropHearts(Math.ceil(estimateHeartCount()/totalSteps - 1));
      }
      
      // No need to return a cleanup function that removes hearts
    }
  }, [showHearts, currentStep, totalSteps]);

  useEffect(() => {
    if (heartsFilled) {
      const allBodies = Matter.Composite.allBodies(engineRef.current.world);
      let poppedHearts = 0;
      const heartBodies = allBodies.filter(body => !body.isStatic);
      const totalHearts = heartBodies.length;
      console.log('Total Hearts:', totalHearts);
      allBodies.forEach(body => {
        if (body.render.sprite && body.render.sprite.texture === heartImage) {
          const randomDelay = Math.random() * 500;
          
          setTimeout(() => {
            const shrinkInterval = setInterval(() => {
              body.render.sprite.xScale -= 0.02;
              body.render.sprite.yScale -= 0.02;
  
              if (body.render.sprite.xScale <= 0 || body.render.sprite.yScale <= 0) {
                Matter.Composite.remove(engineRef.current.world, body);
                clearInterval(shrinkInterval);
                poppedHearts++;
                console.log('Popped Hearts:', poppedHearts);
  
                if (poppedHearts === totalHearts) {
                  console.log('All hearts have popped');
                  setHeartsPopped(true);
                }
              }
            }, 90);
          }, randomDelay);
        }
      });
    }
  }, [heartsFilled]);

  useEffect(() => {
    if (heartsPopped && loveLetter) {
      setShowHearts(false);
      setReadyToShowLoveLetter(true);
    }
  }, [heartsPopped, loveLetter]);

  const handleNextStep = (event) => {
    event.preventDefault();
    console.log('Current Step:', currentStep);
    console.log('Total Steps:', totalSteps);
    if (currentStep < totalSteps) {
      setShowHearts(true); // Ensure hearts are shown on every step
      console.log('Incrementing Step');
      setCurrentStep(currentStep + 1);
    } else {
      // Only reset necessary states
      //setShowHearts(true); // Ensure hearts are shown on every step
      setLoveLetter('');
      console.log('calling love letter api');
      setReadyToShowLoveLetter(false);
      fetch(`${API_URL}/api/love-letter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ fromName, toName, traits, selectedCategory})
      })
        .then(response => response.json())
        .then(data => {
          setLoveLetter(data.loveLetter);
          setHeartsFilled(true);
        });
    }
  };

  const [selectedCategory, setSelectedCategory] = useState(null);
  
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };
  
  const categories = [
    { type: "Poetic " },
    { type: "Funny/Silly" },
    { type: "Romantic" },
    { type: "Passionate" },
    { type: "Appreciation" },
    { type: "Nostalgic" },
    { type: "Apology" },
    { type: "Inspirational" },
    { type: "Sarcastic & Witty" }
  ];
  
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="form-step">
            <h2>Select a Theme for Your Love Letter</h2>
            <div className="category-container">
              {categories.map((category, index) => (
                <div
                  key={index}
                  className={`category-box ${
                    selectedCategory === category.type ? "selected" : ""
                  }`}
                  onClick={() => handleCategorySelect(category.type)}
                >
                  <h3>{category.type}</h3>
                </div>
              ))}
            </div>
            <button
              onClick={handleNextStep}
              className="button"
              disabled={!selectedCategory}
            >
              Next
            </button>
          </div>
        );
      case 2:
        return (
          <div className="form-step">
            <input
              type="text"
              value={toName}
              onChange={(e) => setToName(e.target.value)}
              placeholder="To:"
              className="input-field"
            />
            <button onClick={handleNextStep} className="button">
              Next
            </button>
          </div>
        );
      case 3:
        return (
          <div className="form-step">
            <input
              type="text"
              value={fromName}
              onChange={(e) => setFromName(e.target.value)}
              placeholder="From:"
              className="input-field"
            />
            <button onClick={handleNextStep} className="button">
              Next
            </button>
          </div>
        );
      case 4:
        return (
          <div className="form-step">
            <textarea
              value={traits}
              onChange={(e) => setTraits(e.target.value)}
              placeholder="What you love about them..."
              className="textarea-field"
            />
            <button onClick={handleNextStep} className="button">
              Next
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  const handleGenerateAudio = async () => {
    if (credits < 1) {
      const watchAd = window.confirm('Not enough credits. Would you like to watch an ad to earn a credit?');
      if (watchAd) {
        await watchAdForCredits();
      } else {
        return;
      }
    }

    setIsGeneratingAudio(true);
    try {
      const response = await fetch(`${API_URL}/api/generate-audio`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          loveLetter: loveLetter,
          userId: TEST_USER_ID
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate audio');
      }

      const data = await response.json();
      console.log('Received audio URL:', data.audioUrl); // Log the received URL
      setAudioUrl(data.audioUrl);
      setCredits(prevCredits => prevCredits - 1);
    } catch (error) {
      console.error('Error generating audio:', error);
      alert('Failed to generate audio. Please try again.');
    } finally {
      setIsGeneratingAudio(false);
    }
  };

  const handleDownload = () => {
    if (audioUrl) {
      const link = document.createElement('a');
      link.href = audioUrl;
      link.download = 'love_letter_audio.mp3'; // You can customize the filename here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const watchAdForCredits = () => {
    return new Promise((resolve) => {
      // Simulate watching an ad
      setTimeout(() => {
        setCredits(prevCredits => prevCredits + 1);
        alert('You earned 1 credit!');
        resolve();
      }, 2000); // Simulate a 2-second ad
    });
  };

  useEffect(() => {
    if (audioUrl) {
      console.log('Setting audio source:', audioUrl);
      audioRef.current.innerHTML = `
        <source src="${audioUrl}" type="audio/mpeg">
        <source src="${audioUrl.replace('.mp3', '.ogg')}" type="audio/ogg">
        Your browser does not support the audio element.
      `;
      audioRef.current.load();
    }
  }, [audioUrl]);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch(error => {
        console.error('Error playing audio:', error);
      });
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const audio = audioRef.current;
    
    const handleCanPlayThrough = () => {
      console.log('Audio can play through');
    };

    const handleError = (e) => {
      console.error('Audio error:', e);
    };

    audio.addEventListener('canplaythrough', handleCanPlayThrough);
    audio.addEventListener('error', handleError);

    return () => {
      audio.removeEventListener('canplaythrough', handleCanPlayThrough);
      audio.removeEventListener('error', handleError);
    };
  }, []);

  return (
    <div className="app-container" ref={containerRef}>
      <h1>Love Letter Generator</h1>
      {(!readyToShowLoveLetter) && (
        <>
          <div className="heart-images-container">
            <img src={heartImage} alt="Heart" className="heart-image" />
          </div>
          <div className="form-container">
            <TransitionGroup>
              <CSSTransition
                key={currentStep}
                timeout={1000}
                classNames="fade"
              >
                {renderStep()}
              </CSSTransition>
            </TransitionGroup>
          </div>
        </>
      )}
      {readyToShowLoveLetter && (
        <>
          <div className="love-letter">
            <h2>Your Love Letter</h2>
            <p>{loveLetter}</p>
          </div>
          <button onClick={copyToClipboard} className="copy-button button">
            Copy
          </button>
          {copySuccess && <span className="copy-success">{copySuccess}</span>}
          {!audioUrl && (
            <button 
              onClick={handleGenerateAudio} 
              className="audio-button button"
              disabled={isGeneratingAudio}
            >
              {isGeneratingAudio ? 'Generating...' : `Generate Audio`}
            </button>
          )}
          {audioUrl && (
            <>
              <button 
                onClick={handlePlayPause} 
                className="play-button button"
              >
                {isPlaying ? 'Pause' : 'Play'}
              </button>
              <button 
                onClick={handleDownload} 
                className="download-button button"
              >
                Download Audio
              </button>
            </>
          )}
        </>
      )}
      {showHearts && <div className="hearts-container" ref={sceneRef}></div>}
      <p>Credits: {credits}</p>
      {credits < 1 && (
        <button onClick={watchAdForCredits}>Watch Ad for Credits</button>
      )}
    </div>
  );
}

export default App;